import useValueListener from "../../components/utilities/useValueListener";

import { useSession } from "./session";

/**
 * Listens to whether the user has an authenticated session.
 * Runs callback when the session was authenticated, but now isn't.
 */
const useSessionRemovedListener = (onSessionRemoved = () => {}) => {
	const { hasAuthenticatedSession } = useSession();

	useValueListener({
		value: hasAuthenticatedSession,
		onChange({ previous, value }) {
			const hasSessionBeenRemoved = previous && !value;
			if (hasSessionBeenRemoved) {
				onSessionRemoved();
			}
		},
	});
};

export default useSessionRemovedListener;
