const verifiableBankAccountPossibleTypes = [
	"NZDVerifiableBankAccount",
	"AUDVerifiableBankAccount",
	"GBPVerifiableBankAccount",
	"EURVerifiableBankAccount",
	"USDVerifiableBankAccount",
];

const possibleTypes = {
	Opportunity: ["Offer"],
	OpportunityContentBlock: [
		"OpportunityFrequentlyAskedQuestionsBlock",
		"OfferOverviewBlock",
		"OfferHighlightsBlock",
		"OpportunityHighlightsBlock",
		"OfferTermsBlock",
		"OpportunityAssetsBlock",
		"OpportunityMetricsBlock",
		"OpportunityDocumentsBlock",
		"OpportunityImagesContentBlock",
		"OpportunityTwoColumnContentBlock",
		"OpportunitySingleColumnContentBlock",
		"OpportunityGalleryImagesContentBlock",
		"FundStructureOptionsBlock",
	],
	Notification: [
		"VerifyAddressNotification",
		"AddInvestingEntityNotification",
		"ProvideSourceOfFundsNotification",
		"ConfirmEmailAddressNotification",
		"VerifyIdentityNotification",
		"VerifyBiometricIdentityNotification",
		"ConfirmIdentityDocumentDetailsNotification",
		"ProvideBeneficialOwnerContactDetailsNotification",
		"ProvideCityOfBirthNotification",
		"ProvideInvestingEntityDocumentsNotification",
		"ReattemptVerifyBiometricIdentityNotification",
		"ReattemptVerifyAddressNotification",
		"ReattemptProvideSourceOfFundsNotification",
		"AddBankAccountNotification",
		"VerifyNZDBankAccountNotification",
		"VerifyAUDBankAccountNotification",
		"VerifyEURBankAccountNotification",
		"VerifyGBPBankAccountNotification",
		"VerifyUSDBankAccountNotification",
		"RenewAustralianAccreditationNotification",
		"RenewNewZealandAccreditationNotification",
		"RenewAccreditationNotification",
		"ReattemptConfirmAccreditationNotification",
		"ConfirmAustralianAccreditationNotification",
		"ConfirmNewZealandAccreditationNotification",
		"ConfirmAccreditationNotification",
		"ConfirmUnsupportedCountryAccreditationNotification",
	],
	AccountNotification: [
		"VerifyAddressNotification",
		"AddInvestingEntityNotification",
		"ConfirmEmailAddressNotification",
		"VerifyIdentityNotification",
		"VerifyBiometricIdentityNotification",
		"ConfirmIdentityDocumentDetailsNotification",
		"ProvideCityOfBirthNotification",
		"ReattemptVerifyBiometricIdentityNotification",
		"ReattemptVerifyAddressNotification",
	],
	InvestingEntityNotification: [
		"ProvideSourceOfFundsNotification",
		"ProvideBeneficialOwnerContactDetailsNotification",
		"ProvideInvestingEntityDocumentsNotification",
		"ReattemptProvideSourceOfFundsNotification",
		"AddBankAccountNotification",
		"VerifyNZDBankAccountNotification",
		"VerifyAUDBankAccountNotification",
		"VerifyEURBankAccountNotification",
		"VerifyGBPBankAccountNotification",
		"VerifyUSDBankAccountNotification",
		"RenewAustralianAccreditationNotification",
		"RenewNewZealandAccreditationNotification",
		"RenewAccreditationNotification",
		"ConfirmAustralianAccreditationNotification",
		"ConfirmNewZealandAccreditationNotification",
		"ConfirmAccreditationNotification",
		"ReattemptConfirmAccreditationNotification",
		"ConfirmUnsupportedCountryAccreditationNotification",
	],
	Accreditation: ["RetailOnlyAccreditation", "WholesaleAccreditation"],
	VerifiableBankAccount: verifiableBankAccountPossibleTypes,
	Transaction: [
		"SecondaryUnitPurchaseTransaction",
		"SecondaryUnitSaleTransaction",
		"NewInvestmentTransaction",
		"AutoReInvestmentTransaction",
		"DistributionPaymentTransaction",
		"DistributionTransaction",
		"WithdrawalTransaction",
		"FundExitTransaction",
		"DepositTransaction",
		"OffMarketTransferTransaction",
		"RedemptionTransaction",
		"TaxAttributionTransaction",
	],
	HoldingTransaction: [
		"SecondaryUnitPurchaseTransaction",
		"SecondaryUnitSaleTransaction",
		"NewInvestmentTransaction",
		"AutoReInvestmentTransaction",
		"DistributionTransaction",
		"FundExitTransaction",
		"OffMarketTransferTransaction",
		"RedemptionTransaction",
		"TaxAttributionTransaction",
	],
	MoneyAccount: [
		"Wallet",
		"BankAccount",
		...verifiableBankAccountPossibleTypes,
	],
	MaybeVerifiableBankAccount: [
		"BankAccount",
		...verifiableBankAccountPossibleTypes,
	],
	PortfolioOrder: [
		"AllocationRequest",
		"SellOrder",
		"BuyOrder",
		"UnitRedemptionRequest",
	],
	OfferDataBlock: ["OfferTextBlock", "OfferTableBlock"],
};

export default possibleTypes;
