"use client";

import React, { createContext, useContext } from "react";

export const forceToBoolean = (str: string | null | undefined) =>
	Boolean(Number(str));

const variables = {
	graphQLApiUri:
		process.env.NEXT_PUBLIC_API_SERVICE_DOMAIN &&
		`https://${process.env.NEXT_PUBLIC_API_SERVICE_DOMAIN}/query`,
	apiMockIsEnabled: forceToBoolean(
		process.env.NEXT_PUBLIC_GRAPHQL_MOCK_IS_ENABLED,
	),
	frontAppChatId: process.env.NEXT_PUBLIC_FRONT_APP_CHAT_ID,
	frontAppChatIsEnabled: forceToBoolean(
		process.env.NEXT_PUBLIC_FRONT_APP_CHAT_IS_ENABLED,
	),
	hotJarIsEnabled: forceToBoolean(process.env.NEXT_PUBLIC_HOT_JAR_IS_ENABLED),
	hotJarId: process.env.NEXT_PUBLIC_HOT_JAR_ID,
	cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
	hotJarSnippetVersion: process.env.NEXT_PUBLIC_HOT_JAR_SNIPPET_VERSION,
	mapboxApiAccessToken: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
	passbaseApiKey: process.env.NEXT_PUBLIC_PASSBASE_API_KEY,
	runtimeEnvironment: process.env.NEXT_PUBLIC_RUNTIME_ENVIRONMENT,
	releaseId: process.env.NEXT_PUBLIC_RELEASE_ID,
	sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
	segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
	segmentSnippetVersion: process.env.NEXT_PUBLIC_SEGMENT_SNIPPET_VERSION,
	datadogApplicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
	datadogClientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
	runningDemos: forceToBoolean(process.env.NEXT_PUBLIC_RUNNING_DEMOS),
} as const;

const EnvironmentVariablesContext = createContext<typeof variables>(variables);

export function useEnvironmentVariables() {
	const context = useContext(EnvironmentVariablesContext);

	if (!context) {
		throw new Error(
			"useEnvironmentVariables should always be used within an EnvironmentVariablesProvider",
		);
	}

	return context;
}

export const EnvironmentVariablesProvider = ({
	children,
	...rest
}: {
	children: React.ReactNode;
}) => (
	<EnvironmentVariablesContext.Provider value={variables} {...rest}>
		{children}
	</EnvironmentVariablesContext.Provider>
);
