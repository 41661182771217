import { useCallback } from "react";

import {
	formatIfApolloError,
	parseTransactionIdFromGraphQLErrors,
} from "../formatErrors";

import { useLogger } from "../../contexts/logger";

export default function useCaptureGraphQLError() {
	const { captureException } = useLogger();

	return useCallback(
		(error) => {
			if (!error) {
				return;
			}

			const transactionId = parseTransactionIdFromGraphQLErrors({ ...error });
			const tags = transactionId ? { "transaction-id": transactionId } : {};

			captureException(formatIfApolloError(error), { tags });
		},
		[captureException],
	);
}
