"use client";

import { useRef, useEffect } from "react";
import { useConstantRefCallback } from "@jasperlabs/hooks";

const useValueListener = ({ onChange, value }) => {
	const onChangeRef = useConstantRefCallback(onChange);
	const previousRef = useRef(value);

	useEffect(() => {
		const { current: previous } = previousRef;
		if (value !== previous) {
			onChangeRef({ previous, value });
			previousRef.current = value;
		}
	}, [onChangeRef, value]);
};

export default useValueListener;
