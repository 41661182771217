import {
	tenants,
	type TenantKey,
	type Tenant,
	type TenantId,
	type LiveTenant,
} from "./config";
import { getTenantByID, getTenantByKey, getDictionary } from "./utils";
import { type TenantConfig, type SupportedTenantCountry } from "./types";
import {
	dictionaries,
	type Dictionary,
	type DictionaryKey,
} from "./dictionaries";

export {
	tenants,
	getTenantByID,
	getTenantByKey,
	getDictionary,
	dictionaries,
	TenantKey,
	Tenant,
	TenantId,
	LiveTenant,
	TenantConfig,
	Dictionary,
	DictionaryKey,
	SupportedTenantCountry,
};
