import * as queries from "./queries";

export const INITIALIZE_APP = {
	query: queries.INITIALIZE_APP,
	data: {
		session: {
			id: "",
			created: "",
			token: "",
			expires: "",
			impersonatedBy: null,
			__typename: "Session",
		},
		me: {
			id: "",
			firstName: "",
			middleName: "",
			lastName: "",
			preferredName: "",
			email: "",
			dayOfBirth: null,
			monthOfBirth: null,
			yearOfBirth: null,
			availableFunds: null,
			timeZone: "",
			intercomHash: "",
			frontChatHash: "",
			isAccountLocked: null,
			accountLockedReason: "",
			hasProvidedAccountDetails: null,
			placeOfBirth: {
				id: "",
				city: "",
				country: {
					id: "",
					name: "",
					__typename: "Country",
				},
				__typename: "PlaceOfBirth",
			},
			address: {
				id: "",
				addressLine1: "",
				addressLine2: "",
				suburb: "",
				city: "",
				country: {
					id: "",
					name: "",
					isoAlpha2: "",
					__typename: "Country",
				},
				postCode: "",
				status: "",
				__typename: "Address",
			},
			primaryCitizenship: {
				id: "",
				name: "",
				__typename: "Country",
			},
			phoneNumber: {
				phoneNumber: "",
				callingCode: "",
				__typename: "PhoneNumber",
			},
			country: {
				id: "",
				callingCode: "",
				__typename: "Country",
			},
			__typename: "Account",
		},
	},
};
