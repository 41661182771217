import { useLazyQuery } from "@apollo/client";

import useEffectOnce from "../../components/utilities/useEffectOnce";

const useDeferredQuery = (...args) => {
	const [fetch, result] = useLazyQuery(...args);

	useEffectOnce(fetch);

	return result;
};

export default useDeferredQuery;
