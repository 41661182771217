import useValueListener from "../../components/utilities/useValueListener";

import { useSession } from "./session";

/**
 * Listens to whether the user's session changes.
 * Runs on sign in, sign out or session refresh.
 */
const useSessionOnChange = (onSessionChanged = () => {}) => {
	const { sessionToken } = useSession();

	useValueListener({
		value: sessionToken,
		onChange: onSessionChanged,
	});
};

export default useSessionOnChange;
