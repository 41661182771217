import { useCallback, useEffect, useRef } from "react";

export default function useTimeout() {
	const ids = useRef([]);

	useEffect(() => {
		const cleanupTimeouts = () => {
			ids.current.forEach(clearTimeout);
		};

		return cleanupTimeouts;
	}, []);

	// setTimeout takes a callback and a delay, then returns the id associated with the timeout.
	const safeSetTimeout = useCallback((callback, delay) => {
		const id = setTimeout(callback, delay);

		ids.current.push(id);

		return id;
	}, []);

	return safeSetTimeout;
}

export function useRunOnNextTick() {
	const safeSetTimeout = useTimeout();

	return useCallback(
		(callback) => safeSetTimeout(callback, 0),
		[safeSetTimeout],
	);
}
