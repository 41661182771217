export const dictionaries = {
	GLOBAL: {
		investingEntities: {
			PRIMARY_TAX_ID: "Primary tax ID No. (TIN)",
			SECONDARY_TAX_ID: "Secondary tax ID No. (TIN)",
			WHT: "Withholding tax rate (WHT)",
			NRWHT: "Non-resident withholding tax rate (NRWHT)",
			PIR: "Prescribed investor rate (PIR)",
			REGISTRATION_NUMBER: "Registration number",
			BUSINESS_NUMBER: "Business No.",
		},
	},
	AU: {
		investingEntities: {
			PRIMARY_TAX_ID: "Primary tax ID No. (TIN) / tax file No. (TFN)",
			SECONDARY_TAX_ID: "Secondary tax ID No. (TIN) / tax file No. (TFN)",
			WHT: "Withholding tax rate (WHT)",
			NRWHT: "Non-resident withholding tax rate (NRWHT)",
			PIR: "Prescribed investor rate (PIR)",
			REGISTRATION_NUMBER: "Registration No. / ACN",
			BUSINESS_NUMBER: "Business No. / ABN",
		},
	},
	NZ: {
		investingEntities: {
			PRIMARY_TAX_ID: "Primary tax ID No. (TIN) / IRD No.",
			SECONDARY_TAX_ID: "Secondary tax ID No. (TIN) / IRD No.",
			WHT: "Withholding tax rate (WHT) / RWT",
			NRWHT: "Non-resident withholding tax rate (NWHT) / NRWT",
			PIR: "Prescribed investor rate (PIR)",
			REGISTRATION_NUMBER: "Registration No. / Company No.",
			BUSINESS_NUMBER: "Business No. / NZBN",
		},
	},
	US: {
		investingEntities: {
			PRIMARY_TAX_ID: "Primary tax ID No. (TIN) / EIN",
			SECONDARY_TAX_ID: "Secondary tax ID No. (TIN) / EIN",
			WHT: "Withholding tax rate (WHT)",
			NRWHT: "Non-resident withholding tax rate (NWHT)",
			PIR: "Prescribed investor rate (PIR)",
			REGISTRATION_NUMBER: "Registration No. / Company No.",
			BUSINESS_NUMBER: "Business No. / State Entity No.",
		},
	},
} as const;

export type Dictionary = (typeof dictionaries)[keyof typeof dictionaries];

export type DictionaryKey = keyof typeof dictionaries;
