import * as R from "ramda";

export const parseTransactionIdFromGraphQLErrors = R.compose(
	R.path(["extensions", "transactionID"]),
	R.defaultTo({}),
	R.find(R.hasPath(["extensions", "transactionID"])),
	R.propOr([], "graphQLErrors"),
);

const graphQLErrorsReducer = (acc, { message, path }) => `${acc}
Message: ${message}, Path: ${path}`;

export const formatGraphQLErrors = (graphQLErrors) => {
	const PREFIX = "Apollo error > GraphQL error >";
	try {
		return new Error(graphQLErrors.reduce(graphQLErrorsReducer, PREFIX));
	} catch {
		return new Error(
			`${PREFIX} Unknown type - a graphQL error occured, but could not be correctly logged. Expected array of errors but got ${typeof graphQLErrors} for ${graphQLErrors}`,
		);
	}
};

export const formatNetworkError = (networkError) => {
	const formattedError = new Error(
		`Apollo error > Network error > Original error message: ${networkError.message}`,
	);

	formattedError.originalError = networkError;

	return formattedError;
};

export const formatIfApolloError = (rawError) => {
	const { graphQLErrors, networkError } = { ...rawError };

	if (graphQLErrors?.length) {
		return formatGraphQLErrors(graphQLErrors);
	}

	if (networkError) {
		return formatNetworkError(networkError);
	}

	return rawError;
};
