import { useEffect, useRef } from "react";
import lockr, { doKeysMatch } from "./lockr";

export default function useLocalStorageListener(key, onChange) {
	const onChangeRef = useRef(onChange);

	useEffect(() => {
		onChangeRef.current = onChange;
	}, [onChange]);

	useEffect(() => {
		function handleChange({ key: rawKey }) {
			if (!rawKey || doKeysMatch(key, rawKey)) {
				onChangeRef.current(lockr.get(key));
			}
		}

		window.addEventListener("storage", handleChange);

		return () => {
			window.removeEventListener("storage", handleChange);
		};
	}, [key]);
}
