"use client";

import React, { useRef, useCallback } from "react";
import { ApolloProvider } from "@apollo/client";
import { useIsomorphicLayoutEffect } from "usehooks-ts";
import { useTenantContext } from "tenants/TenantProvider";

import { useSession } from "../contexts/session";
import { useEnvironmentVariables } from "../contexts/environmentVariables";
import { useLogger } from "../contexts/logger";

import useValueListener from "../components/utilities/useValueListener";
import { useRunOnNextTick } from "../components/utilities/useTimeout";

import { useCreateApolloClient } from "./client";

function useGetLatestSessionToken() {
	const { sessionToken } = useSession();
	const ref = useRef(sessionToken);

	useIsomorphicLayoutEffect(() => {
		ref.current = sessionToken;
	}, [sessionToken]);

	return useCallback(() => ref.current, []);
}

const GraphQLProvider = ({ children }: { children: React.ReactNode }) => {
	const { tenant } = useTenantContext();

	const { graphQLApiUri } = useEnvironmentVariables();
	const { expireSession, sessionToken } = useSession();
	const getToken = useGetLatestSessionToken();
	const runOnNextTick = useRunOnNextTick();
	const { captureMessage } = useLogger();

	const client = useCreateApolloClient({
		uri: graphQLApiUri,
		getToken,
		onInvalidToken: expireSession,
		onError: captureMessage,
		tenantId: tenant.tenantId,
	});

	useValueListener({
		value: sessionToken,
		onChange({ previous }: { previous: any }) {
			// If there was a token then reset the store regardless of the changed value
			if (previous) {
				// @ts-ignore
				runOnNextTick(client.resetStore);
			}
		},
	});

	return <ApolloProvider client={client!}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
