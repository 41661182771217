import * as R from "ramda";

import useLocalStorage from "./useLocalStorage";
import useLocalStorageListener from "./useLocalStorageListener";

const isNotEqual = R.complement(R.equals);

export default function useLocalStorageWithListener(key, initialValue) {
	const [value, setValue] = useLocalStorage(key, initialValue);

	useLocalStorageListener(key, (newValue) => {
		const resolvedValue = newValue ?? initialValue;
		if (isNotEqual(value, resolvedValue)) {
			setValue(resolvedValue);
		}
	});

	return [value, setValue];
}
