import { useEffect, useRef } from "react";

/**
 * @deprecated Use Apollo's `useMutation` hook with the `onError` option instead.
 */
export default function useMutationOnError(
	onError,
	[_, { loading, error, called, ...rest }],
) {
	const callback = useRef(onError);
	const hasFailed = !loading && called && Boolean(error);

	useEffect(() => {
		if (hasFailed) {
			callback.current(error);
		}
	}, [hasFailed, error]);

	useEffect(() => {
		callback.current = onError;
	}, [onError]);

	return [_, { loading, error, called, ...rest }];
}
