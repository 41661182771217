import { useCallback, useRef } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

/**
 * Returns a constant reference to a callback which can be safely added
 * to dependency arrays without triggering the effect or memoization.
 * Maintains an up to date copy of the provided function to avoid
 * referencing stale state/props at calltime.
 */
const useConstantRefCallback = (fn: any) => {
	const ref = useRef(fn);

	// copy a ref to the callback scoped to the current state/props on each render
	useIsomorphicLayoutEffect(() => {
		ref.current = fn;
	});

	return useCallback((...args: any[]) => ref.current(...args), []);
};

export default useConstantRefCallback;
