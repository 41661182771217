import { useRef } from "react";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError as onErrorLink } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import { defaultQueries } from "../queries";
import { formatGraphQLErrors, formatNetworkError } from "../formatErrors";
import useInMemoryCache from "./useInMemoryCache";

const createClient = ({
	cache,
	tenantId,
	getToken,
	onInvalidToken,
	onError,
	uri,
}) => {
	/**
	 * When a user logs in or creates a new account the first action performed
	 * is a mutation. A mutation response is only saved if there is already an
	 * object in the cache, so we prime the cache with a default (empty) query.
	 */
	function writeDefaultToCache() {
		cache.writeQuery(defaultQueries.INITIALIZE_APP);
	}

	const handleErrors = onErrorLink(
		({ graphQLErrors = [], networkError, operation }) => {
			// Set context based on response
			const { response } = operation.getContext();
			const transactionId = response?.headers?.get("x-request-id") ?? "";

			const tags = {
				...(transactionId && { "transaction-id": transactionId }),
			};

			// Separate errors by message
			const byIsTokenInvalid = ({ message }) => message === "ErrorTokenInvalid";
			const not =
				(pred) =>
				(...args) =>
					!pred(...args);
			const invalidTokenError = graphQLErrors.find(byIsTokenInvalid);
			const remainingGraphQLErrors = graphQLErrors.filter(
				not(byIsTokenInvalid),
			);

			if (response && response.errors?.length > 0) {
				// Ignore NotImplemented errors as they will just return null for the field
				response.errors = response.errors.filter((error) => {
					if (
						error.message === "ErrorNotImplemented" ||
						error.message.includes("ErrorNotImplemented")
					) {
						return false;
					}

					return true;
				});

				// Set errors to null if there are none
				if (response.errors.length === 0) {
					response.errors = null;
				}
			}

			// Handle errors based on type
			if (invalidTokenError) {
				onInvalidToken(invalidTokenError, { tags });
			}

			if (remainingGraphQLErrors.length) {
				onError(formatGraphQLErrors(remainingGraphQLErrors), { tags });
			}

			if (networkError) {
				onError(formatNetworkError(networkError), { tags });
			}
		},
	);

	const addAuthorizationHeader = setContext((_, { headers = {} }) => {
		const token = getToken();
		if (token) {
			return {
				headers: {
					...headers,
					"X-Authorization": `Bearer: ${token}`,
					"X-Tenant-ID": tenantId,
				},
			};
		}

		return {
			headers: {
				...headers,
				"X-Tenant-ID": tenantId,
			},
		};
	});

	const linkToNetwork = createUploadLink({ uri, credentials: "include" });

	writeDefaultToCache();

	const client = new ApolloClient({
		cache,
		link: ApolloLink.from([
			addAuthorizationHeader,
			handleErrors,
			linkToNetwork,
		]),
	});

	client.onClearStore(writeDefaultToCache);
	client.onResetStore(writeDefaultToCache);

	return client;
};

const useCreateApolloClient = (config) => {
	const clientRef = useRef(null);
	const cache = useInMemoryCache();

	if (!clientRef.current) {
		clientRef.current = createClient({ ...config, cache });
	}

	return clientRef.current;
};

export default useCreateApolloClient;
