import { useCallback, useState } from "react";
import useInterval from "../../../components/utilities/useInterval";

const thirtySecondsInMilliseconds = 30 * 1000;
const nowISOString = () => new Date(Date.now()).toISOString();

// Used in a test
// eslint-disable-next-line import/no-unused-modules
export const getHasSessionExpired = (expiresAt, rightNow = nowISOString()) => {
	const parsedExpiresAt = Date.parse(expiresAt);

	return (
		Number.isNaN(parsedExpiresAt) || Date.parse(rightNow) > parsedExpiresAt
	);
};

function useForceUpdate() {
	const [, update] = useState(Date.now());

	return useCallback(() => {
		update(Date.now());
	}, []);
}

export default function useSessionExpiration(expiration) {
	const forceUpdate = useForceUpdate();

	useInterval(() => {
		if (getHasSessionExpired(expiration)) {
			forceUpdate();
		}
	}, thirtySecondsInMilliseconds);

	return getHasSessionExpired(expiration);
}
