import { useEffect } from "react";

import useFreeze from "./useFreeze";

const useEffectOnce = (effect) => {
	/**
	 * The return value of useFreeze never changes, so we can
	 * add it as a dependency to useEffect, confident that it
	 * will not cause the effect to re-run.
	 */
	const frozenEffect = useFreeze(() => effect);

	useEffect(() => frozenEffect(), [frozenEffect]);
};

export default useEffectOnce;
