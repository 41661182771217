"use client";

import useCaptureGraphQLError from "./useCaptureGraphQLError";
import useDeferredQuery from "./useDeferredQuery";
import useMutationOnError from "./useMutationOnError";
import useMutationOnSuccess, {
	useMutationOnSuccessHighPriority,
} from "./useMutationOnSuccess";

export {
	useDeferredQuery,
	useCaptureGraphQLError,
	useMutationOnError as useLazyQueryOnError,
	useMutationOnSuccess as useLazyQueryOnSuccess,
	useMutationOnError,
	useMutationOnSuccess,
	useMutationOnSuccessHighPriority,
};
