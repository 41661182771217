"use client";

import React, { createContext, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import { datadogRum } from "@datadog/browser-rum";

import { useEnvironmentVariables } from "../environmentVariables";
import { useSessionRemovedListener } from "../session";

const LOGGING_LEVEL = {
	FATAL_EXCEPTION: "FATAL_EXCEPTION",
	EXCEPTION: "EXCEPTION",
	MESSAGE: "MESSAGE",
	UNKNOWN: "UNKNOWN",
};

const loggingMethod = {
	// eslint-disable-next-line no-console
	[LOGGING_LEVEL.FATAL_EXCEPTION]: console.error,
	// eslint-disable-next-line no-console
	[LOGGING_LEVEL.EXCEPTION]: console.error,
	// eslint-disable-next-line no-console
	[LOGGING_LEVEL.MESSAGE]: console.log,
	// eslint-disable-next-line no-console
	[LOGGING_LEVEL.UNKNOWN]: console.log,
};

const sentryCaptureUsing = (method, defaultOptions = {}) => {
	const capture = Sentry[method];

	return (event, options = {}) => {
		const { tags, level, loggingLevel, runtimeEnvironment } = {
			...defaultOptions,
			...options,
		};

		Sentry.withScope((scope) => {
			if (level) {
				scope.setLevel(level);
			}

			if (tags) {
				Object.entries(tags).forEach(([key, value]) => {
					scope.setTag(key, value);
				});
			}

			capture(event);
		});

		if (runtimeEnvironment === "caruso-dev") {
			const log = loggingMethod[loggingLevel ?? LOGGING_LEVEL.UNKNOWN];
			log(`[${loggingLevel ?? LOGGING_LEVEL.UNKNOWN}]`, event);
		}
	};
};

function useDatadogRealUserMonitoringInitialise() {
	const {
		datadogApplicationId,
		datadogClientToken,
		releaseId,
		runtimeEnvironment,
	} = useEnvironmentVariables();

	useEffect(() => {
		if (!datadogApplicationId || !datadogClientToken) {
			// Datadog Application ID or Client Token not provided, gracefully exit
			return;
		}

		datadogRum.init({
			applicationId: datadogApplicationId,
			clientToken: datadogClientToken,
			site: "datadoghq.com",
			service: "web-app",
			env: runtimeEnvironment,
			version: releaseId,
			sampleRate: 100,
			trackInteractions: true,
			defaultPrivacyLevel: "mask",
		});

		datadogRum.startSessionReplayRecording();
	}, [datadogApplicationId, datadogClientToken, releaseId, runtimeEnvironment]);
}

function useSentryInitialise() {
	const {
		sentryDSN = "",
		runtimeEnvironment,
		releaseId,
	} = useEnvironmentVariables();

	if (sentryDSN && !runtimeEnvironment) {
		throw new Error(
			"Runtime environment is not defined. Logging cannot be correctly configured",
		);
	}

	useEffect(() => {
		try {
			Sentry.init({
				dsn: sentryDSN,
				environment: runtimeEnvironment,
				...(releaseId && { release: releaseId }),
				ignoreErrors: [
					// GraphQL errors are 'unhandled' & propagate up to Sentry
					/^(GraphQL error:)/,
					/^(GraphQL Errors:)/,
					/^(TypeError: Failed to fetch)/,
					/^(Network error:)/,
				],
			});

			return () => {
				Sentry.init({ dsn: "" });
			};
		} catch {
			return () => {};
		}
	}, [sentryDSN, runtimeEnvironment, releaseId]);
}

const LoggerContext = createContext();

export function useLogger() {
	const context = useContext(LoggerContext);

	if (!context) {
		throw new Error("useLogger should always be used within a LoggerProvider");
	}

	return context;
}

export const LoggerProvider = ({ children, ...rest }) => {
	useSentryInitialise();
	useSessionRemovedListener(() => {
		Sentry.setUser({});
	});

	useDatadogRealUserMonitoringInitialise();

	const { runtimeEnvironment } = useEnvironmentVariables();

	const value = useMemo(
		() => ({
			setUser: Sentry.setUser,
			// Sometimes we want to capture, but not send to Sentry
			captureNothing() {},
			captureMessage: sentryCaptureUsing("captureMessage", {
				loggingLevel: LOGGING_LEVEL.MESSAGE,
				runtimeEnvironment,
			}),
			captureException: sentryCaptureUsing("captureException", {
				loggingLevel: LOGGING_LEVEL.EXCEPTION,
				runtimeEnvironment,
			}),
			captureFatalException: sentryCaptureUsing("captureException", {
				loggingLevel: LOGGING_LEVEL.FATAL_EXCEPTION,
				runtimeEnvironment,
				level: "fatal",
			}),
		}),
		[runtimeEnvironment],
	);

	return (
		<LoggerContext.Provider value={value} {...rest}>
			{children}
		</LoggerContext.Provider>
	);
};

LoggerProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
