"use client";

import React from "react";
import {
	type LiveTenant,
	type Tenant,
	getTenantByKey,
} from "@jasperlabs/tenant-config";
import { useEnvironmentVariables } from "contexts/environmentVariables";
import { TENANT_IDS_RUNNING_DEMOS } from "./constants";

const TenantContext = React.createContext<{
	tenant: LiveTenant;
	demoTenant?: Tenant | null;
} | null>(null);

export function useTenantContext() {
	const context = React.useContext(TenantContext);

	if (!context) {
		throw new Error("useTenantContext must be used within a TenantProvider");
	}

	return context;
}

export function useActiveTenant() {
	const { runningDemos } = useEnvironmentVariables();
	const { tenant, demoTenant } = useTenantContext();

	// Default to ventura for cypress tests
	if (typeof window !== "undefined" && window.Cypress) {
		return getTenantByKey("VENTURA_DEV");
	}

	if (tenant.status !== "live") {
		throw new Error(`Tenant id not configured correctly`);
	}

	if (
		runningDemos &&
		TENANT_IDS_RUNNING_DEMOS.includes(tenant.tenantId) &&
		demoTenant
	) {
		return demoTenant;
	}

	return tenant;
}

export default function TenantProvider({
	children,
	tenant,
	demoTenant,
}: {
	children: React.ReactNode;
	tenant: LiveTenant;
	demoTenant?: Tenant | null;
}) {
	const value = React.useMemo(
		() => ({
			tenant,
			demoTenant,
		}),
		[tenant, demoTenant],
	);

	return (
		<TenantContext.Provider value={value}>{children}</TenantContext.Provider>
	);
}
