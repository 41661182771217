import {
	tenants,
	type TenantId,
	type Tenant,
	type TenantKey,
	type LiveTenant,
} from "./config";

import {
	dictionaries,
	type Dictionary,
	type DictionaryKey,
} from "./dictionaries";

/**
 * Get a tenant by id.
 */
export function getTenantByID(tenantId: TenantId): LiveTenant {
	const tenant = Object.values(tenants).find((tenant) => {
		if (tenant.status !== "live") {
			return false;
		}
		return tenant.tenantId === tenantId;
	});

	if (!tenant) {
		throw new Error(`Tenant with id ${tenantId} not found`);
	}

	if (tenant.status !== "live") {
		throw new Error(`Tenant with id ${tenantId} is not live`);
	}

	return tenant;
}

/**
 * Get a tenant by key.
 */
export function getTenantByKey(key: TenantKey): Tenant {
	const tenant = tenants[key];

	if (!tenant) {
		throw new Error(`Tenant with key ${key} not found`);
	}

	return tenant;
}

/**
 * Get a dictionary by key. If the key is not provided or not found, return the global dictionary.
 */
export function getDictionary(key?: string): Dictionary {
	if (!key || !(key in dictionaries)) {
		return dictionaries.GLOBAL;
	}

	return dictionaries[key as DictionaryKey];
}
