import { useCallback, useState } from "react";
import lockr from "./lockr";

const isBrowser = typeof window !== "undefined";

export default function useLocalStorage(key, initialValue) {
	const [value, setState] = useState(
		isBrowser ? lockr.get(key, initialValue) : initialValue,
	);

	const setValue = useCallback(
		(setter) => {
			const isFunction = typeof setter === "function";
			const newValue = isFunction
				? setter(lockr.get(key, initialValue))
				: setter;

			setState(newValue);
			lockr.set(key, newValue);
		},
		[key, initialValue],
	);

	return [value, setValue];
}
