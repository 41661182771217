import { useState, useCallback } from "react";
import Cookies from "js-cookie";

const useCookie = (key) => {
	const [item, setInnerValue] = useState(Cookies.get(key));

	const setValue = useCallback(
		(value, options) => {
			setInnerValue(value);
			Cookies.set(key, value, options);
		},
		[key],
	);

	const removeItem = useCallback(
		(options) => {
			setInnerValue(undefined);
			Cookies.remove(key, options);
		},
		[key],
	);

	return [item, setValue, removeItem];
};

export default useCookie;
