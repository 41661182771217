import { useRef, useEffect } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

/**
 * @deprecated Use Apollo's `useMutation` hook with the `onCompleted` option instead.
 */
export default function useMutationOnSuccess(
	onSuccess,
	[_, { loading, error, called, data, ...rest }],
) {
	const callback = useRef(onSuccess);
	const hasSucceeded = !loading && called && !error;

	useEffect(() => {
		if (hasSucceeded) {
			callback.current(data);
		}
	}, [hasSucceeded, data]);

	useEffect(() => {
		callback.current = onSuccess;
	}, [onSuccess]);

	return [_, { loading, error, called, data, ...rest }];
}

/**
 * @deprecated Use Apollo's `useMutation` hook with the `onCompleted` option instead.
 */
export const useMutationOnSuccessHighPriority = (
	onSuccess,
	[_, { loading, error, called, data, ...rest }],
) => {
	const callback = useRef(onSuccess);
	const hasSucceeded = !loading && called && !error;

	useIsomorphicLayoutEffect(() => {
		if (hasSucceeded) {
			callback.current(data);
		}
	}, [hasSucceeded, data]);

	useEffect(() => {
		callback.current = onSuccess;
	}, [onSuccess]);

	return [_, { loading, error, called, data, ...rest }];
};
