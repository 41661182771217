"use client";

import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

import useSessionExpirationCheck from "./useSessionExpirationCheck";
import { useLocalStorageWithListener } from "../../components/utilities/useLocalStorage";
import useCookie from "../../components/utilities/useCookie";
import { useEnvironmentVariables } from "../environmentVariables";

const SESSION_STORAGE_KEY = "session";
const sessionContext = createContext();

export function useSession() {
	const context = useContext(sessionContext);

	if (!context) {
		throw new Error(
			"useSession should always be used within a SessionProvider",
		);
	}

	return context;
}

export const SessionProvider = ({ children, ...rest }) => {
	const [session = {}, setSession] = useLocalStorageWithListener(
		SESSION_STORAGE_KEY,
		{
			token: "",
			expires: "",
		},
	);

	const { cookieDomain } = useEnvironmentVariables();

	const [, setCookie, removeCookie] = useCookie("jasper_is_authenticated");

	const hasSessionExpired = useSessionExpirationCheck(session.expires);

	const value = useMemo(() => {
		const sessionToken = session.token;
		const hasAuthenticatedSession = Boolean(sessionToken);

		return {
			sessionToken,
			hasAuthenticatedSession,
			hasSessionExpired,
			createSession({ token = "", expires = "" } = {}) {
				setSession({ token, expires });
				setCookie(1, { expires: new Date(expires), domain: cookieDomain });
			},
			removeSession() {
				if (hasAuthenticatedSession) {
					setSession({ token: "", expires: "" });
					removeCookie({ domain: cookieDomain });
				}
			},
			expireSession() {
				if (session.expires) {
					setSession((s) => ({ ...s, expires: "" }));
				}
			},
		};
	}, [
		session,
		hasSessionExpired,
		setSession,
		setCookie,
		removeCookie,
		cookieDomain,
	]);

	return (
		<sessionContext.Provider value={value} {...rest}>
			{children}
		</sessionContext.Provider>
	);
};

SessionProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
