import { gql } from "@apollo/client";

export const PAGE_INFO = gql`
	fragment pageInfoFields on PageInfo {
		endCursor
		totalCount
		hasNextPage
	}
`;

export const CURRENCY = gql`
	fragment currencyFields on Currency {
		id
		name
		symbol
		abbreviation
		decimalSeparator
		thousandsSeparator
	}
`;

export const MONEY = gql`
	fragment moneyFields on Money {
		name
		value
		currency {
			...currencyFields
		}
	}
	${CURRENCY}
`;

export const IMAGE = gql`
	fragment imageFields on Image {
		name
		index
		url
		contentType
	}
`;

export const REMOTE_ASSET = gql`
	fragment remoteAssetFields on RemoteAsset {
		id
		index
		size
		name
		url
	}
`;

const ADDRESS = gql`
	fragment addressFields on Address {
		id
		addressLine1
		addressLine2
		suburb
		city
		postCode
		country {
			id
			name
			isoAlpha2
		}
		status
	}
`;

export const HOLDING_METRIC = gql`
	fragment holdingMetricFields on HoldingMetric {
		id
		name
		value
		tooltip
		effectiveDate
	}
`;

export const HOLDING_DOCUMENT = gql`
	fragment holdingDocumentFields on HoldingDocument {
		title
		published
		file {
			...remoteAssetFields
		}
		category {
			id
			name
		}
	}
	${REMOTE_ASSET}
`;

export const BANK_ACCOUNT = gql`
	fragment bankAccountFields on BankAccount {
		id
		name
		accountNumber
		bsbCode
		ukSortCode
		routingNumber
		locked
		currency {
			...currencyFields
		}
	}
	${CURRENCY}
`;

export const VERIFIABLE_BANK_ACCOUNT = gql`
	fragment verifiableBankAccountFields on VerifiableBankAccount {
		id
		created
		name
		nickname
		status
		currency {
			...currencyFields
		}
		... on NZDVerifiableBankAccount {
			accountNumber
		}
		... on AUDVerifiableBankAccount {
			accountNumber
			bsbCode
		}
		... on USDVerifiableBankAccount {
			accountNumber
			routingNumber
		}
		... on GBPVerifiableBankAccount {
			accountNumber
			sortCode
		}
		... on EURVerifiableBankAccount {
			accountNumber
		}
	}
	${CURRENCY}
`;

export const DEPRECATED_BENEFICIAL_OWNER = gql`
	fragment deprecatedBeneficialOwnerFields on BeneficialOwner {
		investingEntityPrimaryOwnerFirstName
		investingEntityPrimaryOwnerLastName
		investingEntityName
		firstName
		middleName
		lastName
		identityVerificationStatus
		addressVerificationStatus
		address {
			...addressFields
		}
	}
	${ADDRESS}
`;

export const BENEFICIAL_OWNER = gql`
	fragment beneficialOwnerFields on BeneficialOwner {
		id
		email
		firstName
		middleName
		lastName
		investingEntityName
		investingEntityPrimaryOwnerFirstName
		investingEntityPrimaryOwnerLastName
		addressVerificationStatus
		basicDetailsVerificationStatus
		identityDocumentVerificationStatus
		biometricIdentityVerificationStatus
		address {
			...addressFields
			placeId
		}
	}
	${ADDRESS}
`;

export const ASSET_KEY_METRICS = gql`
	fragment assetKeyMetricsFields on AssetKeyMetrics {
		id
		effectiveDate
		purchasePrice {
			...moneyFields
		}
		latestValuation {
			...moneyFields
		}
		weightedAverageLeaseTerm
		occupancy {
			numerator
			denominator
		}
		landArea
		netLettableArea
	}
`;

export const ASSET = gql`
	fragment assetFields on Asset {
		id
		name
		slug
		location
		vimeoVideoId
		optionalCardImageUrl
		thumbnailImageUrl
		shortDescription
		landArea
		netLettableArea
		latestValuation {
			...moneyFields
		}
		latestKeyMetrics {
			...assetKeyMetricsFields
		}
	}
	${MONEY}
	${ASSET_KEY_METRICS}
`;

export const OPPORTUNITY_ASSET = gql`
	fragment opportunityAssetFields on Asset {
		...assetFields
		descriptionBlock {
			content
		}
		metricsBlock {
			forecastedAt
			updatedAt
			metrics {
				id
				name
				value
				tooltip
				index
			}
		}
		galleryImages {
			...imageFields
		}
	}
	${ASSET}
	${IMAGE}
`;

export const ACCOUNT = gql`
	fragment accountFields on Account {
		id
		firstName
		middleName
		lastName
		preferredName
		email
		dayOfBirth
		monthOfBirth
		yearOfBirth
		availableFunds
		timeZone
		frontChatHash
		isAccountLocked
		accountLockedReason
		hasProvidedAccountDetails
		phoneNumber {
			phoneNumber
			callingCode
		}
		country {
			id
			callingCode
		}
		address {
			...addressFields
		}
		placeOfBirth {
			id
			city
			country {
				id
				name
			}
		}
		primaryCitizenship {
			id
			name
		}
	}
	${ADDRESS}
`;

export const SESSION = gql`
	fragment sessionFields on Session {
		id
		token
		expires
		impersonatedBy {
			id
			firstName
			lastName
		}
	}
`;

export const FEATURE_FLAG = gql`
	fragment featureFlagFields on FeatureFlag {
		name
		isEnabled
	}
`;

export const ACCREDITATION = gql`
	fragment accreditationFields on Accreditation {
		id
		updated
		country {
			id
			name
		}
		... on WholesaleAccreditation {
			status
			document {
				viewURL
				template
				signatures {
					id
					isMe
					name
					email
					status
					signingURL
					relationship
				}
			}
		}
	}
`;

export const INVESTING_ENTITY = gql`
	fragment investingEntityFields on InvestingEntity {
		id
		name
		number
		address
		entityTypeEnum
		trustType
		companyType
		partnershipType
		sourceOfFunds
		relationshipEnum
		taxIdentificationNumber
		nzPrescribedInvestorRate
		frequencyOfInvestment
		reasonForInvesting
		canBeReset
		country {
			id
			name
		}
		accreditationCountry {
			id
			name
		}
		accreditation {
			...accreditationFields
		}
		bankAccounts {
			...verifiableBankAccountFields
		}
		documents {
			...remoteAssetFields
		}
		jointIndividualSecondInvestor {
			legalName
			sourceOfFunds
			taxIdentificationNumber
			nzPrescribedInvestorRate
			taxResidencyCountry {
				id
				name
			}
		}
	}
	${REMOTE_ASSET}
	${ACCREDITATION}
	${VERIFIABLE_BANK_ACCOUNT}
`;

const PORTFOLIO_RETURNS_POINT = gql`
	fragment portfolioReturnsPointFields on PortfolioReturnsPoint {
		cashReturns {
			...moneyFields
		}
		cashAndCapitalReturns {
			...moneyFields
		}
		returnsDate
	}
	${MONEY}
`;

const PORTFOLIO_RETURNS_SUMMARY = gql`
	fragment portfolioReturnsSummaryFields on PortfolioReturnsSummary {
		totalReturns {
			...moneyFields
		}
		proportionalReturn {
			numerator
			denominator
		}
		annualisedReturn {
			numerator
			denominator
		}
	}
	${MONEY}
`;

export const PORTFOLIO_RETURNS = gql`
	fragment portfolioReturnsFields on PortfolioReturns {
		returns {
			...portfolioReturnsPointFields
		}
		totalCashReturns {
			...portfolioReturnsSummaryFields
		}
		totalCashAndCapitalReturns {
			...portfolioReturnsSummaryFields
		}
		hasReturnsAcrossRange
	}
	${PORTFOLIO_RETURNS_SUMMARY}
	${PORTFOLIO_RETURNS_POINT}
`;

export const WALLET = gql`
	fragment walletFields on Wallet {
		id
		currency {
			...currencyFields
		}
		balance {
			...moneyFields
		}
		proportionOfTotalPortfolio {
			numerator
			denominator
		}
		depositDetails {
			reference
			bankAccount {
				...bankAccountFields
			}
		}
	}
	${CURRENCY}
	${MONEY}
	${BANK_ACCOUNT}
`;

export const CURRENCY_WITH_WALLET = gql`
	fragment currencyWithWalletFields on Currency {
		...currencyFields
		wallet {
			...walletFields
		}
	}
	${CURRENCY}
	${WALLET}
`;

export const FUND = gql`
	fragment fundFields on Fund {
		id
		name
		blurb
		location
		assetStructure
		heroImageUrl
		cardImageUrl
		thumbnailImageUrl
		isMarketplaceFund
		unitPrice {
			...moneyFields
		}
		currency {
			...currencyWithWalletFields
		}
		autoReinvest {
			isAvailable ## todo remove once feature is released
			unitPrice
			availability
		}
		exitDetails {
			overviewMetrics {
				id
				name
				value
				index
			}
		}
	}
	${MONEY}
	${CURRENCY_WITH_WALLET}
`;

export const OFFER_INVESTOR = gql`
	fragment offerInvestorFields on OfferInvestor {
		outstandingRequirementsToInvest
		hasRegisteredInterest
		orders {
			id
			value
			status
			unitCount
		}
	}
`;

export const OFFER = gql`
	fragment offerFields on Offer {
		id
		name
		slug
		status
		isAccreditationRequired
		pricePerUnit
		estimatedPricePerUnit
		minInvestment
		unitsStepCount
		totalUnitCount
		minUnitsPerInvestment
		maxUnitsPerInvestment
		offerDocumentationUrl
		hasOfferDocumentation
		fundsDeadline
		openDate
		fund {
			...fundFields
		}
		investor {
			...offerInvestorFields
		}
		overviewMetrics {
			id
			name
			value
			tooltip
			index
		}
		allocationRequestDisclaimerText
		cardImageUrl
	}
	${FUND}
	${OFFER_INVESTOR}
`;

export const ORDER = gql`
	fragment orderFields on Order {
		id
		value
		created
		unitPrice
		unitCount
		# TODO(@andy): rename this back to 'status' when we can
		status: statusEnum
		investingEntity {
			id
			name
		}
		offer {
			id
			slug
			pricePerUnit
			estimatedPricePerUnit
			overviewMetrics {
				id
				name
				value
				tooltip
				index
			}
			fund {
				...fundFields
			}
		}
	}
	${CURRENCY}
	${FUND}
`;

export const DEPRECATED_TRANSACTION = gql`
	fragment deprecatedTransactionFields on Transaction {
		id
		processedAt
		amount {
			...moneyFields
		}
		... on NewInvestmentTransaction {
			status
			unitCount
			pricePerUnit {
				...moneyFields
			}
			from {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
			to {
				... on Fund {
					id
					name
				}
			}
		}
		... on AutoReInvestmentTransaction {
			unitCount
			pricePerUnit {
				...moneyFields
			}
			from {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
			to {
				... on Fund {
					id
					name
				}
			}
		}
		... on DistributionTransaction {
			exchangedAt
			exchangeRate {
				...moneyFields
			}
			preExchangeAmount {
				...moneyFields
			}
			from {
				... on Fund {
					id
					name
				}
			}
			to {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
		}
		... on FundExitTransaction {
			from {
				... on Fund {
					id
					name
				}
			}
			to {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
		}
		... on SecondaryUnitPurchaseTransaction {
			from {
				id
				currency {
					...currencyFields
				}
			}
			fund {
				id
				name
			}
			unitCount
			unitPrice {
				...moneyFields
			}
			status
		}
		... on SecondaryUnitSaleTransaction {
			to {
				id
				currency {
					...currencyFields
				}
			}
			fund {
				id
				name
			}
			unitCount
			unitPrice {
				...moneyFields
			}
			status
			fees {
				...moneyFields
			}
		}
		... on WithdrawalTransaction {
			reference
			status
			from {
				... on BankAccount {
					id
					name
					accountNumber
				}
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
			}
			to {
				... on VerifiableBankAccount {
					id
					nickname
				}
			}
		}
		... on DepositTransaction {
			reference
			from {
				... on VerifiableBankAccount {
					id
					nickname
					investingEntity {
						id
						name
					}
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
			to {
				... on BankAccount {
					id
					name
					accountNumber
				}
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
			}
		}
		... on RedemptionTransaction {
			nullableFund: fund {
				id
				name
			}
			status
			unitCount
			unitPrice {
				...moneyFields
			}
		}
		... on OffMarketTransferTransaction {
			nullableFund: fund {
				id
				name
			}
			status
			unitCount
			unitPrice {
				...moneyFields
			}
		}
	}
	${CURRENCY}
	${MONEY}
`;

export const TRANSACTION = gql`
	fragment transactionFields on Transaction {
		id
		processedAt
		amount {
			...moneyFields
		}
		... on NewInvestmentTransaction {
			status
			unitCount
			pricePerUnit {
				...moneyFields
			}
			from {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
			to {
				... on Fund {
					id
					name
				}
			}
		}
		... on AutoReInvestmentTransaction {
			unitCount
			pricePerUnit {
				...moneyFields
			}
			from {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
			to {
				... on Fund {
					id
					name
				}
			}
		}
		... on DistributionTransaction {
			exchangedAt
			exchangeRate {
				...moneyFields
			}
			preExchangeAmount {
				...moneyFields
			}
			from {
				... on Fund {
					id
					name
				}
			}
			to {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
		}
		... on FundExitTransaction {
			from {
				... on Fund {
					id
					name
				}
			}
			to {
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
				... on VerifiableBankAccount {
					id
					nickname
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
		}
		... on SecondaryUnitPurchaseTransaction {
			from {
				id
				currency {
					...currencyFields
				}
			}
			fund {
				id
				name
			}
			unitCount
			unitPrice {
				...moneyFields
			}
			status
		}
		... on SecondaryUnitSaleTransaction {
			to {
				id
				currency {
					...currencyFields
				}
			}
			fund {
				id
				name
			}
			unitCount
			unitPrice {
				...moneyFields
			}
			status
			fees {
				...moneyFields
			}
		}
		... on WithdrawalTransaction {
			reference
			status
			from {
				... on BankAccount {
					id
					name
					accountNumber
				}
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
			}
			to {
				... on VerifiableBankAccount {
					id
					nickname
				}
			}
		}
		... on DepositTransaction {
			reference
			from {
				... on VerifiableBankAccount {
					id
					nickname
					investingEntity {
						id
						name
					}
				}
				... on BankAccount {
					id
					name
					accountNumber
				}
			}
			to {
				... on BankAccount {
					id
					name
					accountNumber
				}
				... on Wallet {
					id
					currency {
						...currencyFields
					}
				}
			}
		}
		... on RedemptionTransaction {
			nullableFund: fund {
				id
				name
			}
			status
			unitCount
			unitPrice {
				...moneyFields
			}
		}
		... on OffMarketTransferTransaction {
			nullableFund: fund {
				id
				name
			}
			status
			unitCount
			unitPrice {
				...moneyFields
			}
		}
		... on TaxAttributionTransaction {
			nullableFund: fund {
				id
				name
			}
			status
			unitCount
			unitPrice {
				...moneyFields
			}
		}
	}
	${CURRENCY}
	${MONEY}
`;

export const HOLDING = gql`
	fragment holdingFields on Holding {
		id
		slug
		unitCount
		returnsTarget
		fund {
			...fundFields
		}
		# Deprecated
		unitValue {
			...moneyFields
		}
		totalValue {
			...moneyFields
		}
		# Deprecated
		holdingNetAssetValue {
			...moneyFields
		}
		proportionOfTotalPortfolio {
			numerator
			denominator
		}
		keyMetrics {
			...holdingMetricFields
		}
		keyDates {
			id
			date
			name
		}
	}
	${FUND}
	${MONEY}
	${HOLDING_METRIC}
`;

export const PORTFOLIO = gql`
	fragment portfolioFields on Portfolio {
		queriedAt
		portfolioOrders {
			... on AllocationRequest {
				id
				created
				unitCount
				allocationRequestStatus: status
				offer {
					slug
					estimatedPricePerUnit
					pricePerUnit
					fund {
						id
						name
						thumbnailImageUrl
						currency {
							...currencyFields
							wallet {
								...walletFields
							}
						}
					}
				}
			}
			... on SellOrder {
				id
				created
				unitCount
				totalValue {
					...moneyFields
				}
				askPrice {
					value
				}
				sellOrderStatus: status
			}
			... on BuyOrder {
				id
				created
				unitCount
				totalValue {
					...moneyFields
				}
				bidPrice {
					value
				}
				buyOrderStatus: status
			}
		}
		holdings {
			...holdingFields
		}
		liquidatedHoldings {
			...holdingFields
			liquidationReason
		}
		wallets {
			...walletFields
		}
		totalHoldingsValue {
			...moneyFields
		}
	}
	${HOLDING}
	${MONEY}
	${WALLET}
	${CURRENCY}
`;

export const ACCOUNT_NOTIFICATION = gql`
	fragment accountNotificationFields on AccountNotification {
		id
		status
		sortOrder
	}
`;

export const INVESTING_ENTITY_NOTIFICATION = gql`
	fragment investingEntityNotificationFields on InvestingEntityNotification {
		id
		status
		sortOrder
		... on VerifyNZDBankAccountNotification {
			bankAccount {
				...verifiableBankAccountFields
			}
		}
		... on VerifyAUDBankAccountNotification {
			bankAccount {
				...verifiableBankAccountFields
			}
		}
		... on VerifyEURBankAccountNotification {
			bankAccount {
				...verifiableBankAccountFields
			}
		}
		... on VerifyGBPBankAccountNotification {
			bankAccount {
				...verifiableBankAccountFields
			}
		}
		... on VerifyUSDBankAccountNotification {
			bankAccount {
				...verifiableBankAccountFields
			}
		}
		... on ConfirmUnsupportedCountryAccreditationNotification {
			country {
				id
				name
			}
		}
	}
	${VERIFIABLE_BANK_ACCOUNT}
`;
